@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icon-custom/icomoon.eot?72g0gc");
  src: url("../fonts/icon-custom/icomoon.eot?72g0gc#iefix") format("embedded-opentype"), url("../fonts/icon-custom/icomoon.ttf?72g0gc") format("truetype"), url("../fonts/icon-custom/icomoon.woff?72g0gc") format("woff"), url("../fonts/icon-custom/icomoon.svg?72g0gc#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-custom-add:before {
  content: "\e900";
}

.icon-custom-arrow-left:before {
  content: "\e901";
}

.icon-custom-arrow-right:before {
  content: "\e902";
}

.icon-custom-bell:before {
  content: "\e903";
}

.icon-custom-brand:before {
  content: "\e904";
}

.icon-custom-cart:before {
  content: "\e905";
}

.icon-custom-color:before {
  content: "\e906";
}

.icon-custom-edit:before {
  content: "\e907";
}

.icon-custom-fabrics:before {
  content: "\e908";
}

.icon-custom-font-size:before {
  content: "\e909";
}

.icon-custom-hash:before {
  content: "\e90a";
}

.icon-custom-logo:before {
  content: "\e90b";
}

.icon-custom-message:before {
  content: "\e90c";
}

.icon-custom-move:before {
  content: "\e90d";
}

.icon-custom-opacity:before {
  content: "\e90e";
}

.icon-custom-piping:before {
  content: "\e90f";
}

.icon-custom-player-name:before {
  content: "\e910";
}

.icon-custom-rotate:before {
  content: "\e911";
}

.icon-custom-search:before {
  content: "\e912";
}

.icon-custom-slider:before {
  content: "\e913";
}

.icon-custom-star:before {
  content: "\e914";
}

.icon-custom-team-name:before {
  content: "\e915";
}

.icon-custom-trash:before {
  content: "\e916";
}

.icon-custom-user:before {
  content: "\e917";
}

.icon-custom-visible:before {
  content: "\e918";
}

@font-face {
  font-family: 'Gotham Book';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Book"), url("../fonts/GothamBook.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Book Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Book Italic"), url("../fonts/GothamBookItalic.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Light';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Light"), url("../fonts/GothamLight.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Light Italic"), url("../fonts/GothamLightItalic.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Medium"), url("../fonts/GothamMedium.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Medium"), url("../fonts/GothamMedium_1.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Medium"), url("../fonts/GothamMediumItalic.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Bold"), url("../fonts/GothamBold.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Bold"), url("../fonts/Gotham-Bold.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Bold Italic"), url("../fonts/GothamBoldItalic.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Black Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Black Regular"), url("../fonts/Gotham-Black.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Light Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Light Regular"), url("../fonts/Gotham-Light.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Thin Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Thin Regular"), url("../fonts/Gotham-Thin.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham XLight Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham XLight Regular"), url("../fonts/Gotham-XLight.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Book Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Book Italic"), url("../fonts/Gotham-BookItalic.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Thin Italic"), url("../fonts/Gotham-ThinItalic.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham Ultra Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham Ultra Italic"), url("../fonts/Gotham-UltraItalic.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Gotham XLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Gotham XLight Italic"), url("../fonts/Gotham-XLightItalic.woff") format("woff");
  font-display: block;
}

@font-face {
  font-family: 'Allison Tessa Oblique';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/AllisonTessaOblique.woff") format("woff");
  font-display: block;
}

.gotham-Medium {
  font-family: "Gotham Medium" !important;
}

.gotham-Bold {
  font-family: "Gotham Bold" !important;
}

body, button, a, span, p, h1, h2, h3, h4, h5, h6 {
  font-family: "Gotham Light Regular" !important;
}

button, .uk-button {
  vertical-align: middle !important;
}

button.button-red-riddle, .uk-button.button-red-riddle {
  color: #ffffff !important;
  border-color: #ba2334 !important;
  background-color: #ba2334 !important;
}

button.button-red-riddle:hover, .uk-button.button-red-riddle:hover {
  color: #ffffff !important;
}

button.button-red-riddle:disabled, .uk-button.button-red-riddle:disabled {
  color: #ffffff !important;
  border-color: #c6c6c6 !important;
  background-color: #c6c6c6 !important;
}

button.button-red-riddle.button-outline, .uk-button.button-red-riddle.button-outline {
  color: #ba2334 !important;
  border-color: #ba2334 !important;
  background-color: transparent !important;
}

button.button-red-riddle.button-outline:disabled, .uk-button.button-red-riddle.button-outline:disabled {
  color: #c6c6c6 !important;
  border-color: #c6c6c6 !important;
}

button.button-red-danger, .uk-button.button-red-danger {
  color: #ffffff !important;
  border-color: #f0506e !important;
  background-color: #f0506e !important;
}

button.button-red-danger:hover, .uk-button.button-red-danger:hover {
  color: #ffffff !important;
}

button.button-red-danger:disabled, .uk-button.button-red-danger:disabled {
  color: #ffffff !important;
  border-color: #c6c6c6 !important;
  background-color: #c6c6c6 !important;
}

button.button-red-danger.button-outline, .uk-button.button-red-danger.button-outline {
  color: #f0506e !important;
  border-color: #f0506e !important;
  background-color: transparent !important;
}

button.button-red-danger.button-outline:disabled, .uk-button.button-red-danger.button-outline:disabled {
  color: #c6c6c6 !important;
  border-color: #c6c6c6 !important;
}

button.button-yellow-warning, .uk-button.button-yellow-warning {
  color: #ffffff !important;
  border-color: #faa05a !important;
  background-color: #faa05a !important;
}

button.button-yellow-warning:hover, .uk-button.button-yellow-warning:hover {
  color: #ffffff !important;
}

button.button-yellow-warning:disabled, .uk-button.button-yellow-warning:disabled {
  color: #ffffff !important;
  border-color: #c6c6c6 !important;
  background-color: #c6c6c6 !important;
}

button.button-yellow-warning.button-outline, .uk-button.button-yellow-warning.button-outline {
  color: #faa05a !important;
  border-color: #faa05a !important;
  background-color: transparent !important;
}

button.button-yellow-warning.button-outline:disabled, .uk-button.button-yellow-warning.button-outline:disabled {
  color: #c6c6c6 !important;
  border-color: #c6c6c6 !important;
}

button.button-green-success, .uk-button.button-green-success {
  color: #ffffff !important;
  border-color: #32d296 !important;
  background-color: #32d296 !important;
}

button.button-green-success:hover, .uk-button.button-green-success:hover {
  color: #ffffff !important;
}

button.button-green-success:disabled, .uk-button.button-green-success:disabled {
  color: #ffffff !important;
  border-color: #c6c6c6 !important;
  background-color: #c6c6c6 !important;
}

button.button-green-success.button-outline, .uk-button.button-green-success.button-outline {
  color: #32d296 !important;
  border-color: #32d296 !important;
  background-color: transparent !important;
}

button.button-green-success.button-outline:disabled, .uk-button.button-green-success.button-outline:disabled {
  color: #c6c6c6 !important;
  border-color: #c6c6c6 !important;
}

button.button-blue-primary, .uk-button.button-blue-primary {
  color: #ffffff !important;
  border-color: #1e87f0 !important;
  background-color: #1e87f0 !important;
}

button.button-blue-primary:hover, .uk-button.button-blue-primary:hover {
  color: #ffffff !important;
}

button.button-blue-primary:disabled, .uk-button.button-blue-primary:disabled {
  color: #ffffff !important;
  border-color: #c6c6c6 !important;
  background-color: #c6c6c6 !important;
}

button.button-blue-primary.button-outline, .uk-button.button-blue-primary.button-outline {
  color: #1e87f0 !important;
  border-color: #1e87f0 !important;
  background-color: transparent !important;
}

button.button-blue-primary.button-outline:disabled, .uk-button.button-blue-primary.button-outline:disabled {
  color: #c6c6c6 !important;
  border-color: #c6c6c6 !important;
}

button.button-dark, .uk-button.button-dark {
  color: #ffffff !important;
  border-color: #7a7a7a !important;
  background-color: #7a7a7a !important;
}

button.button-dark:hover, .uk-button.button-dark:hover {
  color: #ffffff !important;
}

button.button-dark:disabled, .uk-button.button-dark:disabled {
  color: #ffffff !important;
  border-color: #c6c6c6 !important;
  background-color: #c6c6c6 !important;
}

button.button-dark.button-outline, .uk-button.button-dark.button-outline {
  color: #7a7a7a !important;
  border-color: #7a7a7a !important;
  background-color: transparent !important;
}

button.button-dark.button-outline:disabled, .uk-button.button-dark.button-outline:disabled {
  color: #c6c6c6 !important;
  border-color: #c6c6c6 !important;
}

.tab.tab-button.tab-button-red > *.uk-active > .uk-button {
  color: #ffffff !important;
  border-color: #ba2334 !important;
  background-color: #ba2334 !important;
}

.tab.tab-line.tab-line-red > *.uk-active > * {
  color: #ba2334 !important;
  border-color: #ba2334 !important;
}

header {
  border-bottom: 2px solid #ba2334 !important;
}

ul.menu-home .list-item {
  font-family: "Gotham Medium";
}

ul.menu-home .list-item.uk-active > * {
  padding-bottom: 5px !important;
  color: #ba2334 !important;
  border-bottom: 4px solid #ba2334 !important;
}

.uk-text-bold {
  font-family: "Gotham Book" !important;
}

.text-case-reset {
  text-transform: none !important;
}

.text-mini {
  font-size: 12px;
}

span.cut-skus {
  font-size: 11px !important;
}

.text-tiny {
  font-size: 10px;
}

.feather {
  width: 18px;
  height: 18px;
  stroke: currentColor;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.pointer {
  cursor: pointer;
}

.pointer-default {
  cursor: default;
}

.uk-checkbox, .uk-radio {
  height: 14px;
  width: 14px;
}

.uk-checkbox:checked, .uk-radio:checked {
  background-color: #ba2334 !important;
}

.uk-label-disabled {
  background-color: #7a7a7a !important;
  color: white;
}

.fc-white {
  color: #ffffff !important;
}

.fc-black {
  color: #000000 !important;
}

.fc-gray {
  color: #c6c6c6 !important;
}

.fc-midGray {
  color: #808080 !important;
}

.fc-dark {
  color: #7a7a7a !important;
}

.fc-darker {
  color: #343434 !important;
}

.fc-red-riddle {
  color: #ba2334 !important;
}

.fc-red-danger {
  color: #f0506e !important;
}

.fc-yellow-warning {
  color: #faa05a !important;
}

.fc-green-success {
  color: #32d296 !important;
}

.fc-blue-primary {
  color: #1e87f0 !important;
}

.bgc-white {
  background-color: white;
}

.bgc-light {
  background-color: #f9f9f9 !important;
}

.bgc-gray {
  background-color: #c6c6c6 !important;
}

.bgc-darkGray {
  background-color: #646464 !important;
}

.bgc-darkerGray {
  background-color: #232323 !important;
}

.bgc-black {
  background-color: #000000 !important;
}

.bgc-red-riddle {
  background-color: #ba2334 !important;
}

.bdr {
  border: solid;
}

.bdr.bdr-none {
  border: none;
}

.bdr.bdr-normal {
  border-width: 1.5px;
}

.bdr.bdr-bold {
  border-width: 1.5px;
}

.bdr.red-riddle {
  border-color: #ba2334 !important;
}

.bdr.yellow-warning {
  border-color: #faa05a !important;
}

.bdr.lightGray {
  border-color: #ebebeb !important;
}

.bdr.dark {
  border-color: #7a7a7a !important;
}

.table-action-button button {
  color: #7a7a7a !important;
}

.table-action-button button:disabled {
  color: #c6c6c6 !important;
}

.uk-grid-small.grid-tiny {
  margin-left: -5px;
}

.uk-grid-small.grid-tiny > * {
  margin-top: 5px;
  padding-left: 5px;
}

.thumb {
  width: 42px;
  height: 42px;
}

.thumb.thumb-small {
  width: 30px;
  height: 30px;
}

.thumb.thumb-small.thumb-button {
  line-height: 30px;
}

.thumb.thumb-medium {
  width: 80px;
  height: 80px;
}

.thumb.thumb-large {
  width: 100px;
  height: 100px;
}

.thumb.thumb-xlarge {
  width: 150px;
  height: 150px;
}

.tile {
  width: 22px;
  height: 22px;
}

.tile .marker {
  width: 10px;
  height: 10px;
  background-color: white;
  display: block;
}

.tile-fabric .marker {
  padding: 3px;
  color: white;
  background-color: #ba2334 !important;
  display: block;
}

.tile-preview {
  border: 4px solid gray;
}

.side-panel .uk-button {
  background-color: white;
}

.side-panel .con-panel {
  background-color: #f9f9f9 !important;
  border: 1px solid #ebebeb !important;
}

#container {
  padding: 20px;
}

.align-bottom {
  bottom: 0 !important;
}

.ui-slider {
  background: #c6c6c6 !important;
}

.ui-slider-horizontal {
  height: 10px;
}

.ui-slider-pips .ui-slider-pip {
  top: 16px;
}

.ui-slider-pips .ui-slider-label {
  font-size: 12px;
}

.ui-widget-header .ui-state-focus, .ui-widget-header .ui-state-hover, .ui-widget-header .ui-state-default {
  width: 20px;
  height: 14px;
  background: #ba2334 !important;
  border-color: #ba2334 !important;
  top: -3px;
}

.ui-widget-content .ui-state-focus, .ui-widget-content .ui-state-hover, .ui-widget-content .ui-state-default {
  width: 20px;
  height: 14px;
  background: #ba2334 !important;
  border-color: #ba2334 !important;
  top: -3px;
}

.icon-custom {
  vertical-align: middle;
}

.icon-custom.fsz {
  font-size: 26px;
  width: 26px;
  height: 26px;
}

.icon-custom.fsz.fsz-large {
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.icon-custom.fsz.fsz-small {
  font-size: 22px;
  width: 22px;
  height: 22px;
}

.uk-notification .uk-notification-message.uk-notification-message-primary {
  font-size: 14px;
  color: #ffffff !important;
  background-color: #1e87f0 !important;
  border-radius: 5px;
}

.uk-notification .uk-notification-message.uk-notification-message-primary .uk-close {
  color: #f9f9f9 !important;
}

.uk-notification .uk-notification-message.uk-notification-message-primary .uk-close svg {
  width: 10px;
  heigh: 10px;
}

.uk-notification .uk-notification-message.uk-notification-message-success {
  font-size: 14px;
  color: #ffffff !important;
  background-color: #32d296 !important;
  border-radius: 5px;
}

.uk-notification .uk-notification-message.uk-notification-message-success .uk-close {
  color: #f9f9f9 !important;
}

.uk-notification .uk-notification-message.uk-notification-message-success .uk-close svg {
  width: 10px;
  heigh: 10px;
}

.uk-notification .uk-notification-message.uk-notification-message-warning {
  font-size: 14px;
  color: #ffffff !important;
  background-color: #faa05a !important;
  border-radius: 5px;
}

.uk-notification .uk-notification-message.uk-notification-message-warning .uk-close {
  color: #f9f9f9 !important;
}

.uk-notification .uk-notification-message.uk-notification-message-warning .uk-close svg {
  width: 10px;
  heigh: 10px;
}

.uk-notification .uk-notification-message.uk-notification-message-danger {
  font-size: 14px;
  color: #ffffff !important;
  background-color: #f0506e !important;
  border-radius: 5px;
}

.uk-notification .uk-notification-message.uk-notification-message-danger .uk-close {
  color: #f9f9f9 !important;
}

.uk-notification .uk-notification-message.uk-notification-message-danger .uk-close svg {
  width: 10px;
  heigh: 10px;
}

.vh-30 {
  height: 200px !important;
  overflow: auto !important;
}

tr.try {
  background-color: #FEC8CE;
  color: #343434 !important;
  border: 1px solid #ba2334 !important;
}

tr.try button {
  color: #343434 !important;
  border-color: #808080 !important;
}

li.uk-open {
  background-color: #f9f9f9 !important;
}

.accord-toggle-content {
  display: none;
}

.uk-open .accord-toggle-content {
  display: flex;
}

.accord-toggle-button .accord-icon-collapse {
  display: none;
}

.accord-toggle-button .accord-icon-expand {
  display: block;
}

.uk-open .accord-toggle-button {
  display: flex;
}

.uk-open .accord-toggle-button .accord-icon-collapse {
  display: block;
}

.uk-open .accord-toggle-button .accord-icon-expand {
  display: none;
}

.cart-item-list > li .cart-icon-delete {
  display: none;
}

.cart-item-list > li:hover .cart-icon-delete {
  display: block;
}

.contrast-bgc .contrast-text {
  background: inherit;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  filter: invert(1) grayscale(1) contrast(9);
}

.oops {
  color: #ededed;
  font-family: "Gotham Medium Italic" !important;
}

.error-code {
  -webkit-text-fill-color: #ffffff14;
  /* Will override color (regardless of order) */
  -webkit-text-stroke: 3px #fefefe;
  font-family: "Gotham Black Regular" !important;
}

.error-details {
  color: #ededed;
}

.not-accessible {
  color: #ededed;
  font-family: "Gotham Medium" !important;
}

@media screen and (min-width: 641px) {
  .error-page-container {
    max-height: 78vh !important;
  }
}

@media only screen and (max-width: 640px) {
  .error-page-container {
    max-height: 55vh !important;
  }
}

.error-page-container {
  overflow: hidden !important;
}

.modal-popup {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity .15s linear;
  z-index: 1050 !important;
}

.modal-popup.open {
  opacity: 1;
  display: flex;
  z-index: 1050 !important;
}

.modal-popup.open .modal-popup-body {
  transform: translateY(0);
}

#piping-edit-container button.uk-active {
  color: #ffffff !important;
  background-color: #7a7a7a !important;
}

.ui-progressbar {
  height: 5px;
}

.ui-progressbar .ui-progressbar-value {
  height: 100%;
  transition: 0.5s;
}

.ui-widget-content {
  background: #f9f9f9 !important;
}

.ui-widget-header {
  background: #ba2334 !important;
}

.select-style-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}
