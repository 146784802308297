$prefix: '../';

@font-face {
  font-family: 'Gotham Book';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Book'), url($prefix + 'fonts/GothamBook.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Book Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Book Italic'), url($prefix + 'fonts/GothamBookItalic.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Light';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Light'), url($prefix + 'fonts/GothamLight.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Light Italic'), url($prefix + 'fonts/GothamLightItalic.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Medium'), url($prefix + 'fonts/GothamMedium.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Medium'), url($prefix + 'fonts/GothamMedium_1.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Medium'), url($prefix + 'fonts/GothamMediumItalic.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Bold'), url($prefix + 'fonts/GothamBold.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Bold'), url($prefix + 'fonts/Gotham-Bold.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Bold Italic'), url($prefix + 'fonts/GothamBoldItalic.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Black Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Black Regular'), url($prefix + 'fonts/Gotham-Black.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Light Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Light Regular'), url($prefix + 'fonts/Gotham-Light.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Thin Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Thin Regular'), url($prefix + 'fonts/Gotham-Thin.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham XLight Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham XLight Regular'), url($prefix + 'fonts/Gotham-XLight.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Book Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Book Italic'), url($prefix + 'fonts/Gotham-BookItalic.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Thin Italic'), url($prefix + 'fonts/Gotham-ThinItalic.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham Ultra Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Ultra Italic'), url($prefix + 'fonts/Gotham-UltraItalic.woff') format('woff');
  font-display: block;
}


@font-face {
  font-family: 'Gotham XLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham XLight Italic'), url($prefix + 'fonts/Gotham-XLightItalic.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Allison Tessa Oblique';
  font-style: normal;
  font-weight: normal;
  src: url($prefix + 'fonts/AllisonTessaOblique.woff') format('woff');
  font-display: block;
}