@import "colors";
@import "icon-custom";
@import "fonts";

.gotham-Medium {
  font-family: "Gotham Medium" !important;
}

.gotham-Bold {
  font-family: "Gotham Bold" !important;
}

body, button, a, span, p, h1, h2, h3, h4, h5, h6 {
  font-family: "Gotham Light Regular" !important;
}

button, .uk-button {

  vertical-align: middle !important;

  &.button-red-riddle {
    color: $color-white;
    border-color: $color-red-riddle;
    background-color: $color-red-riddle;

    &:hover {
      color: $color-white;
    }

    &:disabled {
      color: $color-white;
      border-color: $color-gray;
      background-color: $color-gray;
    }

    &.button-outline {
      color: $color-red-riddle;
      border-color: $color-red-riddle;
      background-color: $color-transparent;

      &:disabled {
        color: $color-gray;
        border-color: $color-gray;
      }
    }
  }

  &.button-red-danger {
    color: $color-white;
    border-color: $color-red-danger;
    background-color: $color-red-danger;

    &:hover {
      color: $color-white;
    }

    &:disabled {
      color: $color-white;
      border-color: $color-gray;
      background-color: $color-gray;
    }

    &.button-outline {
      color: $color-red-danger;
      border-color: $color-red-danger;
      background-color: $color-transparent;

      &:disabled {
        color: $color-gray;
        border-color: $color-gray;
      }
    }
  }

  &.button-yellow-warning {
    color: $color-white;
    border-color: $color-yellow-warning;
    background-color: $color-yellow-warning;

    &:hover {
      color: $color-white;
    }

    &:disabled {
      color: $color-white;
      border-color: $color-gray;
      background-color: $color-gray;
    }

    &.button-outline {
      color: $color-yellow-warning;
      border-color: $color-yellow-warning;
      background-color: $color-transparent;

      &:disabled {
        color: $color-gray;
        border-color: $color-gray;
      }
    }
  }

  &.button-green-success {
    color: $color-white;
    border-color: $color-green-success;
    background-color: $color-green-success;

    &:hover {
      color: $color-white;
    }

    &:disabled {
      color: $color-white;
      border-color: $color-gray;
      background-color: $color-gray;
    }

    &.button-outline {
      color: $color-green-success;
      border-color: $color-green-success;
      background-color: $color-transparent;

      &:disabled {
        color: $color-gray;
        border-color: $color-gray;
      }
    }
  }

  &.button-blue-primary {
    color: $color-white;
    border-color: $color-blue-primary;
    background-color: $color-blue-primary;

    &:hover {
      color: $color-white;
    }

    &:disabled {
      color: $color-white;
      border-color: $color-gray;
      background-color: $color-gray;
    }

    &.button-outline {
      color: $color-blue-primary;
      border-color: $color-blue-primary;
      background-color: $color-transparent;

      &:disabled {
        color: $color-gray;
        border-color: $color-gray;
      }
    }
  }

  &.button-dark {
    color: $color-white;
    border-color: $color-dark;
    background-color: $color-dark;

    &:hover {
      color: $color-white;
    }

    &:disabled {
      color: $color-white;
      border-color: $color-gray;
      background-color: $color-gray;
    }

    &.button-outline {
      color: $color-dark;
      border-color: $color-dark;
      background-color: $color-transparent;

      &:disabled {
        color: $color-gray;
        border-color: $color-gray;
      }
    }
  }
}

.tab {
  &.tab-button {
    &.tab-button-red {
      &>*.uk-active {
        &> .uk-button {
          color: $color-white;
          border-color: $color-red-riddle;
          background-color: $color-red-riddle;
        }
      }
    }
  }
  &.tab-line {
    &.tab-line-red {
      &>*.uk-active {
        &> * {
          color: $color-red-riddle;
          border-color: $color-red-riddle;
        }
      }
    }
  }
}

header {
  border-bottom: 2px solid $color-red-riddle;
}

ul.menu-home .list-item {

  font-family: "Gotham Medium";

  &.uk-active > * {
    padding-bottom: 5px !important;
    color: $color-red-riddle;
    border-bottom: 4px solid $color-red-riddle;
  }
}

.uk-text-bold {
  font-family: "Gotham Book" !important;
}

.text-case-reset {
  text-transform: none !important;
}

.text-mini {
  font-size: 12px;
}

span.cut-skus{
  font-size: 11px !important;
}

.text-tiny {
  font-size: 10px;
}

.feather {
  width: 18px;
  height: 18px;
  stroke: currentColor;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.pointer {cursor: pointer;}

.pointer-default {cursor: default;}

.uk-checkbox, .uk-radio {
  height: 14px;
  width: 14px;
  &:checked {
    background-color: $color-red-riddle;
  }
}
.uk-label-disabled {
  background-color: $color-dark;
  color: white;
}
//.uk-active {
//  &.class {
//    //style;
//  }
//
//  &.child {
//    .parent.attached & {
//      //style;
//    }
//  }
//
//  &.list-item > * {
//    ul.menu-home & {
//      padding-bottom: 5px !important;
//      color: $color-red-riddle;
//      border-bottom: 4px solid $color-red-riddle;
//    }
//  }
//}

.fc-white {
  color: $color-white;
}
.fc-black {
  color: $color-black;
}
.fc-gray {
  color: $color-gray;
}
.fc-midGray {
  color: $color-migGray;
}
.fc-dark {
  color: $color-dark;
}
.fc-darker {
  color: $color-darker;
}
.fc-red-riddle {
  color: $color-red-riddle;
}
.fc-red-danger {
  color: $color-red-danger;
}
.fc-yellow-warning {
  color: $color-yellow-warning;
}
.fc-green-success {
  color: $color-green-success;
}
.fc-blue-primary {
  color: $color-blue-primary;
}

.bgc-white {
  background-color: white;
}
.bgc-light {
  background-color: $color-light;
}
.bgc-gray {
  background-color: $color-gray;
}
.bgc-darkGray {
  background-color: $color-darkGray;
}
.bgc-darkerGray {
  background-color: $color-darkerGray;
}
.bgc-black {
  background-color: $color-black;
}

.bgc-red-riddle {
  background-color: $color-red-riddle;
}

.bdr {
  border: solid;
  &.bdr-none {border: none;}
  &.bdr-normal {border-width: 1.5px;}
  &.bdr-bold {border-width: 1.5px;}

  &.red-riddle {border-color: $color-red-riddle;}
  &.yellow-warning {border-color: $color-yellow-warning;}
  &.lightGray {border-color: $color-lightGray;}
  &.dark {border-color: $color-dark;}
}

.table-action-button {

  & button {
    color: $color-dark;
  }
  & button:disabled {
    color: $color-gray;
  }
}

.uk-grid-small.grid-tiny  {
  margin-left: -5px;
  & > * {
    margin-top: 5px;
    padding-left: 5px;
  }
}

.thumb {
  width: 42px;
  height: 42px;

  &.thumb-small {
    width: 30px;
    height: 30px;
    &.thumb-button {
      line-height: 30px;
    }
  }
  &.thumb-medium {
    width: 80px;
    height: 80px
  }
  &.thumb-large {
    width: 100px;
    height: 100px
  }
  &.thumb-xlarge {
    width: 150px;
    height: 150px
  }
}

.tile {
    width: 22px;
    height: 22px;
  & .marker {
    width: 10px;
    height: 10px;
    background-color: white;
    display: block;
  }
}

.tile-fabric {
  & .marker {
    padding: 3px;
    color: white;
    background-color: $color-red-riddle;
    display: block;
  }
}

.tile-preview {
  border: 4px solid gray;
}

.side-panel {
  & .uk-button {
    background-color: white ;
  }

  //div.uk-active {
  //  & .uk-button {
  //    color: white;
  //    background-color: $color-red-riddle ;
  //    border-color: $color-red-riddle ;
  //  }
  //}

  & .con-panel {
    background-color: $color-light;
    border: 1px solid $color-lightGray;

    //div.uk-active {
    //  & .uk-button {
    //    color: white;
    //    background-color: $color-dark;
    //    border-color: $color-dark;
    //  }
    //}
  }

}

#container {
  padding: 20px;
}

.align-bottom{
  bottom: 0 !important;
}

.ui-slider {
  background: $color-gray;
}

.ui-slider-horizontal {
  height: 10px;
}
.ui-slider-pips {
  & .ui-slider-pip {
    top: 16px;
  }
  & .ui-slider-label {
    font-size: 12px;
  }
}


.ui-state-focus, .ui-state-hover, .ui-state-default {
  .ui-widget-header & {
    width: 20px;
    height: 14px;
    background: $color-red-riddle;
    border-color: $color-red-riddle;
    top: -3px;
  }
  .ui-widget-content & {
    width: 20px;
    height: 14px;
    background: $color-red-riddle;
    border-color: $color-red-riddle;
    top: -3px;
  }
}

.icon-custom {
  vertical-align: middle;
  &.fsz {
    font-size: 26px;
    width: 26px;
    height: 26px;
    &.fsz-large {
      font-size: 32px;
      width: 32px;
      height: 32px;
    }
    &.fsz-small {
      font-size: 22px;
      width: 22px;
      height: 22px;
    }
  }
}

.uk-notification {
  & .uk-notification-message {
    &.uk-notification-message-primary {
      font-size: 14px;
      color: $color-white;
      background-color: $color-blue-primary;
      border-radius: 5px;
      & .uk-close {
        color: $color-light;
        & svg {
          width: 10px;
          heigh: 10px;
        }
      }
    }
    &.uk-notification-message-success {
      font-size: 14px;
      color: $color-white;
      background-color: $color-green-success;
      border-radius: 5px;
      & .uk-close {
        color: $color-light;
        & svg {
          width: 10px;
          heigh: 10px;
        }
      }
    }
    &.uk-notification-message-warning {
      font-size: 14px;
      color: $color-white;
      background-color: $color-yellow-warning;
      border-radius: 5px;
      & .uk-close {
        color: $color-light;
        & svg {
          width: 10px;
          heigh: 10px;
        }
      }
    }
    &.uk-notification-message-danger {
      font-size: 14px;
      color: $color-white;
      background-color: $color-red-danger;
      border-radius: 5px;
      & .uk-close {
        color: $color-light;
        & svg {
          width: 10px;
          heigh: 10px;
        }
      }
    }
  }
}

.vh-30 {
  height: 200px !important;
  overflow: auto !important;
}


tr.try {
  background-color: #FEC8CE;
  color: $color-darker;
  border: 1px solid $color-red-riddle;

  & button {
    color: $color-darker;
    border-color: $color-migGray;
  }
}


  li.uk-open {
    background-color: $color-light;
  }
.accord-toggle-content {
  display: none;

  .uk-open & {
    display: flex;
  }
}

.accord-toggle-button {

  & .accord-icon-collapse {
    display: none;
  }
  & .accord-icon-expand {
    display: block;
  }

  .uk-open & {
    display: flex;

    & .accord-icon-collapse {
      display: block;
    }
    & .accord-icon-expand {
      display: none;
    }
  }
}

.cart-item-list > li  {
  & .cart-icon-delete {
    display: none;
  }
  &:hover {
    & .cart-icon-delete {
      display: block;
    }
  }
}

.contrast-bgc {
  .contrast-text {
    //color: white;
    //mix-blend-mode: difference;


    background: inherit;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    filter: invert(1) grayscale(1) contrast(9);
  }
}
.oops {
  color: #ededed;
  //mix-blend-mode: color-dodge;
  font-family: "Gotham Medium Italic" !important;
}

.error-code {
  //mix-blend-mode: color-dodge;
  -webkit-text-fill-color: #ffffff14; /* Will override color (regardless of order) */
  -webkit-text-stroke: 3px #fefefe;
  font-family: "Gotham Black Regular" !important;


  //filter:opacity(30%);
  //filter: grayscale(1) contrast(9) opacity(30%) drop-shadow(.05em .05em #646464);
}

.error-details {
  color: #ededed;
}

.not-accessible {
    color: #ededed;
    // mix-blend-mode: color-dodge;
    font-family: "Gotham Medium" !important;
}



//@media screen and (max-width: 1600px) {
//  .error-page-container {
//    max-height: 78vh !important;
//    //display: block;
//  }
//}

@media screen and (min-width: 641px) {
  .error-page-container {
    max-height: 78vh !important;
    //display: none;
  }
}
@media only screen and (max-width: 640px) {
  .error-page-container {
    max-height: 55vh !important;
    //display: block;
  }
}
//@media only screen and (min-width: 640px) {
//  .error-page-container {
//    max-height: 55vh !important;
//  }
//}
.error-page-container {
  //max-height: 55vh !important;
  //max-height: 70vh !important;
  overflow: hidden !important;
}

.modal-popup {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  //padding: 15px 15px;
  background:rgba(0,0,0,.6);
  opacity: 0;
  transition: opacity .15s linear;
  z-index: 1050 !important;

  &.open {
    opacity: 1;
    display: flex;
    z-index: 1050 !important;

    & .modal-popup-body {
      transform: translateY(0);
    }
  }
}

#piping-edit-container {
  button {
    &.uk-active {
      color: $color-white;
      background-color: $color-dark;
    }
  }
}

.ui-progressbar {
  height: 5px;
}

.ui-progressbar .ui-progressbar-value {
  height: 100%;
  transition: 0.5s;
}

.ui-widget-content {
  background: $color-light;
}

.ui-widget-header {
  background: $color-red-riddle;
}

.select-style-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}