$color-red-riddle: #ba2334 !important;


$color-transparent: transparent !important;
$color-white: #ffffff !important;
$color-light: #f9f9f9 !important;
$color-lightGray: #ebebeb !important;
$color-gray: #c6c6c6 !important;
$color-migGray: #808080 !important;
$color-dark: #7a7a7a !important;

$color-darkGray: #646464 !important;
$color-darker: #343434 !important;
$color-darkerGray: #232323 !important;
$color-black: #000000 !important;

$color-red-danger: #f0506e !important;
$color-yellow-warning: #faa05a !important;
$color-green-success: #32d296 !important;
$color-blue-primary: #1e87f0 !important;
