@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icon-custom/icomoon.eot?72g0gc');
  src:  url('../fonts/icon-custom/icomoon.eot?72g0gc#iefix') format('embedded-opentype'),
  url('../fonts/icon-custom/icomoon.ttf?72g0gc') format('truetype'),
  url('../fonts/icon-custom/icomoon.woff?72g0gc') format('woff'),
  url('../fonts/icon-custom/icomoon.svg?72g0gc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-custom-add:before {
  content: "\e900";
}
.icon-custom-arrow-left:before {
  content: "\e901";
}
.icon-custom-arrow-right:before {
  content: "\e902";
}
.icon-custom-bell:before {
  content: "\e903";
}
.icon-custom-brand:before {
  content: "\e904";
}
.icon-custom-cart:before {
  content: "\e905";
}
.icon-custom-color:before {
  content: "\e906";
}
.icon-custom-edit:before {
  content: "\e907";
}
.icon-custom-fabrics:before {
  content: "\e908";
}
.icon-custom-font-size:before {
  content: "\e909";
}
.icon-custom-hash:before {
  content: "\e90a";
}
.icon-custom-logo:before {
  content: "\e90b";
}
.icon-custom-message:before {
  content: "\e90c";
}
.icon-custom-move:before {
  content: "\e90d";
}
.icon-custom-opacity:before {
  content: "\e90e";
}
.icon-custom-piping:before {
  content: "\e90f";
}
.icon-custom-player-name:before {
  content: "\e910";
}
.icon-custom-rotate:before {
  content: "\e911";
}
.icon-custom-search:before {
  content: "\e912";
}
.icon-custom-slider:before {
  content: "\e913";
}
.icon-custom-star:before {
  content: "\e914";
}
.icon-custom-team-name:before {
  content: "\e915";
}
.icon-custom-trash:before {
  content: "\e916";
}
.icon-custom-user:before {
  content: "\e917";
}
.icon-custom-visible:before {
  content: "\e918";
}
